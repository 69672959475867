import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { useState, useEffect } from 'react'
import { CrossCircledIcon, CheckCircledIcon } from "@radix-ui/react-icons";

export default function EnvelopeSignatureStepsManager(props) {
  const { steps, setSteps } = props;

  const [disabledSteps, setDisabledSteps] = useState([]);

  const disableStep = (index) => {
    setSteps((prev_steps) => {
      const item = prev_steps[index];
      if (!item) return prev_steps;
      setDisabledSteps((prev_disabled_steps) => [...prev_disabled_steps, item]);
      return prev_steps.filter((_, i) => i !== index);
    });
  };
  
  const enableStep = (index) => {
    setDisabledSteps((prev_disabled_steps) => {
      const item = prev_disabled_steps[index];
      if (!item) return prev_disabled_steps;
      setSteps((prev_steps) => [...prev_steps, item]);
      return prev_disabled_steps.filter((_, i) => i !== index);
    });
  };

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "12px",
          },
        },
      },
    },
  });

  if (!Array.isArray(steps)) {
    return <></>;
  }

  const translateSteps = (enumerator) => {
    switch (enumerator) {
      case "device_scan":
        return "Escanear dispositivo";
      case "document_display":
        return "Exibir documentos do envelope";
      case "authentication_code":
        return "Validar código de autenticação";
      case "personal_document":
        return "Coletar o documento pessoal";
      case "face_recognition":
        return "Realizar o reconhecimento facial";
      default:
        return "";
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      steps,
      result.source.index,
      result.destination.index
    );

    setSteps(items);
  }

  const baseCardStyles = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    borderRadius: "4px",
    padding: "8px",
    marginBottom: "8px",
  }

  const enabledCardStyles = {
    border: "1px solid gray",
    backgroundColor: "white",
  }
  const disabledCardStyles = {
    backgroundColor: "#ddd",
    color: "gray"
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          alignItems: "center",
          margin: "8px 0",
          userSelect: 'none'
        }}
      >
        <h2 style={{ width: "100%" }}>Passos da assinatura</h2>
        <StrictModeDroppable droppableId="droppable">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ width: "100%" }}
            >
              {steps.map((step, index) => (
                <Draggable key={step.step + index} draggableId={`${step.step}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...baseCardStyles,
                        ...enabledCardStyles,
                        ...provided.draggableProps.style,
                      }}
                    >
                      <div style={{ color: "#0b1f82", fontSize: "12px", width: '24px' }}>
                        {index + 1}° -
                      </div>
                      <div style={{ fontSize: "12px" }}>
                        {translateSteps(step.step)}
                      </div>
                      <ThemeProvider theme={theme}>
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "8px"
                          }}
                        >
                            <Tooltip title={step.step !== 'document_display' ? 'Desabilitar' : 'Não é possível desabilitar este passo'}>
                                <CheckCircledIcon style={{cursor: step.step !== 'document_display' ? "pointer" : "not-allowed"}} onClick={() => step.step !== 'document_display' ? disableStep(index) : {}} color="green" width="16px" height="16px"/>
                            </Tooltip>
                            <div style={{backgroundColor: 'gray', width: '1px', height: '100%'}} />
                            <Tooltip title="Arraste para reorganizar">
                                <div style={{ color: "gray" }}>
                                    &#x2630;
                                </div>
                            </Tooltip>
                        </div>
                      </ThemeProvider>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "8px 0",
          userSelect: 'none'
        }}
      >
        {disabledSteps.map((disabled_step, index) => (
          <div
            style={{
              ...baseCardStyles,
              ...disabledCardStyles,
            }}
          >
          <div style={{ color: "gray", fontSize: "12px", width: '24px' }} />
          <div style={{ fontSize: "12px" }}>
            {translateSteps(disabled_step.step)}
          </div>
          <ThemeProvider theme={theme}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "8px"
              }}
            >
                <Tooltip title='Reabilitar'>
                  <CrossCircledIcon style={{cursor: "pointer"}} onClick={() => enableStep(index)} color="red" width="16px" height="16px"/>
                </Tooltip>
                <div style={{backgroundColor: 'gray', width: '1px', height: '100%'}} />
                <div style={{ color: "gray" }}>
                  &#x2630;
                </div>
            </div>
          </ThemeProvider>
        </div>
        ))} 
      </div>
      <WarningMessages steps={steps} disabledSteps={disabledSteps} />
    </DragDropContext>
  );
}

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

const WarningMessages = (props) => {
  
  const { steps, disabledSteps } = props;

  if (steps.some((step) => step.step === 'personal_document') && disabledSteps.some((step) => step.step === 'face_recognition')) {
    return (
      <div style={{ width: '100%', textAlign: 'center', fontSize: '12px'}}>
        Desabilitar o reconhecimento facial com a coleta de documento ativa irá desabilitar a análise de face match.
      </div>
    );
  }

  if (steps.some((step) => step.step === 'face_recognition') && disabledSteps.some((step) => step.step === 'personal_document')) {
    return (
      <div style={{ width: '100%', textAlign: 'center', fontSize: '12px'}}>
        Desabilitar a coleta de documento com o reconhecimento facial ativo acarretará numa análise de face match com score neutro.
      </div>
    );
  }

  return <></>
}