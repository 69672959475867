// TO BE DEPRECATED WHEN THE REACT ENGINE MOVES AWAY FROM CREATE-REACT-APP

import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { formatPhone } from "../../../../utils/utils";
import axios from "axios";
import InputMask from 'react-input-mask';
import { CheckCircledIcon, Cross1Icon, CrossCircledIcon } from "@radix-ui/react-icons";
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



export default function LegacySignerChangeDialog(props) {
    const [name, setName] = useState(props?.signer.name);
    const [birthdate, setBirthdate] = useState(props?.signer?.birthdate ? props.signer.birthdate.split('-')[2] + '/' + props.signer.birthdate.split('-')[1] + '/' + props.signer.birthdate.split('-')[0] : '');
    const [document, setDocument] = useState(props?.signer?.document_number);
    const [email, setEmail] = useState(props?.signer?.email);
    const [phone, setPhone] = useState(formatPhone((props?.signer?.phone?.area_code ?? "") + (props?.signer?.phone?.number ?? "")));
    const [sendMethod, setSendMethod] = useState(props?.signer?.document_submission_method ?? 'email')
    const [authMethod, setAuthMethod] = useState(props?.signer?.authentication_submission_method ?? 'email')
    const [useDocument, setUseDocument] = useState(props?.signer?.document_number ? true : false)
    const [signatureRole, setSignatureRole] = useState(props?.signer?.signature_role ?? "others")

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState();

    const validateFields = useCallback(() => {
        if (!name) return 'O nome do assinante deve estar preenchido';
        if (useDocument.length === 1 && (!document || document.replaceAll('_', '').length !== 14)) return 'O número de documento do assinante deve estar totalmente preenchido';
        if (!email || email.indexOf('@') === -1 || email.indexOf('@') > email.length - 4) return 'O email do assinante deve estar corretamente preenchido';
        if (sendMethod !== 'email' || authMethod !== 'email') {
            if (!phone || phone.length < 13) return 'O telefone do assinante deve estar corretamente preenchido';
        }
        if (phone && phone.replaceAll('_', '').length < 13) return 'O telefone do assinante deve estar corretamente preenchido';
        if (birthdate && (birthdate.replaceAll('_', '').length < 10 || !isValidDate(birthdate))) return 'A data de nascimento deve ser preenchida com valores válidos';

        return 'Campos validados'

    }, [name, useDocument, document, email, sendMethod, authMethod, phone, birthdate])

    useEffect(() => setValidation(validateFields()), [validateFields]);

    const changeSigner = async () => {
        const payload = {
            name: name,
            document_number: useDocument ? document : undefined,
            birthdate: birthdate ? birthdate.split('/')[2] + '-' + birthdate.split('/')[1] + '-' + birthdate.split('/')[0] : undefined,
            email: email,
            document_submission_method: sendMethod,
            authentication_submission_method: authMethod,
            phone: phone ? {
                international_dial_code: '55',
                area_code: phone.substring(1, 3),
                number: phone.substring(4).trim().replaceAll('-', '').replaceAll('_', '')
            } : undefined,
            signature_role: signatureRole !== 'others' ? signatureRole : undefined
        }
        try {
            await axios.put(`/dash/sign/signer/${props?.signer?.signer_key}/change`, payload);
            setSuccess(true);
        } catch (error) {
            setError(true);
        }
    }

    if (error) {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', padding: '16px 32px', width: '450px'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', fontSize: '20px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px'}}>
                    <>Modificar assinante</>
                    <Cross1Icon onClick={props.onClose} style={{cursor: 'pointer', color: '#555'}} width="20" height="20" />
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "8px"}}>
                    <CrossCircledIcon color="red" width="28" height="28" />
                    <div style={{fontSize: '16px'}}>Um erro inesperado aconteceu</div>
                    <div style={{fontSize: '12px'}}>Por favor, tente novamente mais tarde.</div>
                </div>
                <div style={{backgroundColor: '#0b1f82', color: 'white', fontWeight: 'bold', letterSpacing: '0.5px', padding: '8px', borderRadius: '4px', cursor: 'pointer', fontSize: '12px', marginTop: '24px'}} onClick={props.onClose}>Voltar</div>
            </div>
        )
    }

    if (success) {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', padding: '16px 32px', width: '450px'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', fontSize: '20px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px'}}>
                    <>Modificar assinante</>
                    <Cross1Icon onClick={props.onClose} style={{cursor: 'pointer', color: '#555'}} width="20" height="20" />
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "8px"}}>
                    <CheckCircledIcon color="green" width="28" height="28" />
                    <div style={{fontSize: '16px'}}>Modificação realizada com sucesso</div>
                </div>
                <div style={{backgroundColor: '#0b1f82', color: 'white', fontWeight: 'bold', letterSpacing: '0.5px', padding: '8px', borderRadius: '4px', cursor: 'pointer', fontSize: '12px', marginTop: '24px'}} onClick={props.onClose}>Voltar</div>
            </div>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', padding: '16px 32px', width: '450px'}}>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', fontSize: '20px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px'}}>
                <>Modificar assinante</>
                <Cross1Icon onClick={props.onClose} style={{cursor: 'pointer', color: '#555'}} width="20" height="20" />
            </div>
            <TextField fullWidth InputProps={{style: { fontSize: '16px' }}} InputLabelProps={{style: { fontSize: '14px' }}} label="Nome" variant="standard" value={name} onChange={(e) => setName(e.target.value)} />
            <MaskedDateInput fullWidth InputProps={{style: { fontSize: '16px' }}} InputLabelProps={{style: { fontSize: '14px' }}} label="Data de nascimento (Opcional)" variant="standard" value={birthdate} onChange={(e) => setBirthdate(e.target.value)} />
            
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Checkbox checked={useDocument} onChange={() => setUseDocument(!useDocument)} />
                Solicitar CPF ao Assinante
            </div>

            <MaskedCPFInput disabled={!useDocument} fullWidth InputProps={{style: { fontSize: '16px' }}} InputLabelProps={{style: { fontSize: '14px' }}} label="CPF" variant="standard" value={document} onChange={(e) => setDocument(e.target.value)}/>
            <TextField fullWidth InputProps={{style: { fontSize: '16px' }}} InputLabelProps={{style: { fontSize: '14px' }}} label="Email" variant="standard" value={email} onChange={(e) => setEmail(e.target.value)} />
            <MaskedPhoneInput fullWidth InputProps={{style: { fontSize: '16px' }}} InputLabelProps={{style: { fontSize: '14px' }}} label="Telefone" variant="standard" value={phone} onChange={(e) => setPhone(e.target.value.replaceAll('_', ''))}/>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 386 }}>
                <InputLabel 
                    id="demo-simple-select-standard-label" 
                    sx={{ fontSize: '14px' }}
                >
                    Papel de assinatura
                </InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={signatureRole}
                    onChange={(e) => setSignatureRole(e.target.value)}
                    label="Papel de assinatura"
                    sx={{
                    fontSize: '16px',
                    width: '100%',
                    }}
                    MenuProps={{
                    sx: {
                        '& .MuiMenuItem-root': {
                        fontSize: '16px',
                        },
                    },
                    }}
                >
                    <MenuItem value="signer">Assinante</MenuItem>
                    <MenuItem value="party">Parte</MenuItem>
                    <MenuItem value="witness">Testemunha</MenuItem>
                    <MenuItem value="contractor">Contratante</MenuItem>
                    <MenuItem value="contracted">Contratado</MenuItem>
                    <MenuItem value="transferor">Cedente</MenuItem>
                    <MenuItem value="transferee">Cessionário</MenuItem>
                    <MenuItem value="manager">Administrador</MenuItem>
                    <MenuItem value="attorney">Procurador</MenuItem>
                    <MenuItem value="legal_representative">Representante legal</MenuItem>
                    <MenuItem value="legal_responsible">Responsável legal</MenuItem>
                    <MenuItem value="lawyer">Advogado</MenuItem>
                    <MenuItem value="others">
                        <em style={{color: "#555"}}>Outros / Não definir</em>
                    </MenuItem>
                </Select>
            </FormControl>
            <div style={{width: '100%', fontSize: '14px'}}>Método de envio dos documentos:</div>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', gap: '32px', alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Radio
                        checked={sendMethod === 'email'}
                        onChange={() => setSendMethod('email')}
                        value="email"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'Email' }}
                    />
                    Email
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Radio
                        checked={sendMethod === 'whatsapp'}
                        onChange={() => setSendMethod('whatsapp')}
                        value="whatsapp"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'WhatsApp' }}
                    />
                    WhatsApp
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Radio
                        checked={sendMethod === 'sms'}
                        onChange={() => setSendMethod('sms')}
                        value="sms"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'SMS' }}
                    />
                    SMS
                </div>
            </div>
            <div style={{width: '100%', fontSize: '14px'}}>Método de envio da autenticação:</div>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', gap: '32px', alignItems: 'center'}}>

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Radio
                        checked={authMethod === 'email'}
                        onChange={() => setAuthMethod('email')}
                        value="email"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'Email' }}
                    />
                    Email
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Radio
                        checked={authMethod === 'whatsapp'}
                        onChange={() => setAuthMethod('whatsapp')}
                        value="whatsapp"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'WhatsApp' }}
                    />
                    WhatsApp
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Radio
                        checked={authMethod === 'sms'}
                        onChange={() => setAuthMethod('sms')}
                        value="sms"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'SMS' }}
                    />
                    SMS
                </div>
            </div>
            {validation &&
                <div style={{backgroundColor: validation === 'Campos validados' ? '#46A75855' : '#E54D2E55', width: '100%', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px'}}>
                    <div style={{fontSize: '14px', textAlign: 'center', color: validation === 'Campos validados' ? '#193B2D' : '#641723'}}>{validation}</div>
                </div>
            }

            {validation === 'Campos validados' ?
                <div style={{color: loading ? 'black' : 'white', fontWeight: 'bold', letterSpacing: '0.5px', backgroundColor: loading ? '#ddd' : '#0b1f82', padding: '8px', borderRadius: '4px', cursor: 'pointer', fontSize: '12px', marginTop: '8px'}} onClick={async () => {
                    if (!loading) {
                        setLoading(true);
                        await changeSigner();
                        setLoading(false);
                    }
                }}>Salvar modificações</div>
                :
                <div style={{backgroundColor: '#ddd', fontWeight: 'bold', letterSpacing: '0.5px', padding: '8px', borderRadius: '4px', cursor: 'pointer', fontSize: '12px', marginTop: '8px'}}>Salvar modificações</div>
            }
        </div>
    );
}


const MaskedCPFInput = (props) => (
    <InputMask mask={"999.999.999-99"} maskChar="" value={props.value} onChange={props.onChange} disabled={props.disabled}>
        {(inputProps) => <TextField disabled={props.disabled} fullWidth InputProps={{style: { fontSize: '16px' }}} InputLabelProps={{style: { fontSize: '14px' }}} label="CPF" variant="standard" {...inputProps} type="tel" disableUnderline />}
    </InputMask>
);

const MaskedPhoneInput = (props) => (
    <InputMask mask={"(99) 999999999"} maskChar="" value={props.value} onChange={props.onChange}>
        {(inputProps) => <TextField fullWidth InputProps={{style: { fontSize: '16px' }}} InputLabelProps={{style: { fontSize: '14px' }}} label="Telefone" variant="standard" {...inputProps} type="tel" disableUnderline />}
    </InputMask>
  );

const MaskedDateInput = (props) => (
    <InputMask mask={"99/99/9999"} value={props.value} onChange={props.onChange}>
        {(inputProps) => <TextField fullWidth InputProps={{style: { fontSize: '16px' }}} InputLabelProps={{style: { fontSize: '14px' }}} label="Data de nascimento" variant="standard" {...inputProps} type="tel" disableUnderline />}
    </InputMask>
);

function isValidDate(dateString) {
    const parts = dateString.split('/');
    if (parts.length !== 3) {
        return false;
    }

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month, day);

    return (
        date.getFullYear() === year &&
        date.getMonth() === month &&
        date.getDate() === day
    );
}