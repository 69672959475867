import React, { useState, useEffect, useCallback, useContext } from "react"
import StatusIconText from "../../../components/StatusIconText"
import SendEmailIcon from "@mui/icons-material/ForwardToInbox"
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import { ErrorPage } from "../../AuxiliarPages/ErrorPage/ErrorPage"
import { LoadingPage } from "../../AuxiliarPages/LoadingPage/LoadingPage"
import { CircularProgress, Dialog as MuiDialog, Tooltip } from "@mui/material";
import NotificationDialog from "./NotificationDialog/NotificationDialog"
import { CancelEnvelopeErrorDialog } from "./CancelEnvelopeErrorDialog/CancelEnvelopeErrorDialog"
import { ReportErrorDialog } from "./ReportErrorDialog/ReportErrorDialog"
import { DataField } from "../../../components/DataField"
import BaseButton from "../../../components/BaseButton"
import DownloadIcon from '@mui/icons-material/Download'
import LoadingIcon from "../../../assets/images/loading.svg"
import { DatetimeBlock } from "../../../components/DatetimeBlock"
import AuthContext from "../../../context/auth-context"
import ErrorBoundary from "../../AuxiliarPages/ErrorBoundary/ErrorBoundary"
import EditNoteIcon from '@mui/icons-material/EditNote'
import SignerDataDialog from "./SignerDataDialog/SignerDataDialog"
import { BaseAvatar } from "../../../components/BaseAvatar"
import { formatBirthdateToFront } from "../../../utils/utils"
import FindInPageIcon from '@mui/icons-material/FindInPage';
import PDFContent from "../../../utils/PDFContent/PDFContent";
// import { Dialog, Flex, IconButton, Tooltip } from "@radix-ui/themes";
// import { Pencil1Icon } from "@radix-ui/react-icons";
// import SignerChangeDialog from './SignerChangeDialog';
import LegacySignerChangeDialog from "./LegacySignerChangeDialog";
import { Dialog } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
  },
});

export const EnvelopePage = (props) => {

    const translateSignatureRole = (enumerator) => {
        switch (enumerator) {
            case "signer":
                return "Assinante"
            case "party":
                return "Parte"
            case "witness":
                return "Testemunha"
            case "contractor":
                return "Contratante"
            case "contracted":
                return "Contratado"
            case "transferor":
                return "Cedente"
            case "transferee":
                return "Cessionário"
            case "manager":
                return "Administrador"
            case "attorney":
                return "Procurador"
            case "legal_representative":
                return "Representante legal"
            case "legal_responsible":
                return "Responsável legal"
            case "lawyer":
                return "Advogado"
            default:
                return ""
        }
    }

    const envelopeKey = useParams().envelopeKey
    const navigate = useNavigate()
    const userRoles = useContext(AuthContext).user_data.roles

    if (!userRoles.includes("read_envelope")) {
        navigate("/envelope-inbox")
    }

    const [fetchedData, setFetchedData] = useState(undefined)

    const [error, setError] = useState({
        hasError: false,
        statusCode: 200
    })
    const [loading, setLoading] = useState(true)

    const [notificationError, setNotificationError] = useState({
        hasError: false,
        statusCode: 200
    })
    const [notificationLoading, setNotificationLoading] = useState(false)
    const [notificationDialogOpen, setNotificationDialogOpen] = useState(false)

    const [report, setDocumentReport] = useState(undefined)
    const [reportError, setReportError] = useState(false)
    const [reportLoading, setReportLoading] = useState(Array(fetchedData).fill(false));
    const [envelopeLoading, cancelEnvelopeLoading] = useState(false)
    const [cancelEnvelopeError, setCancelEnvelopeError] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [editSignerDataDialogOpen, setEditSignerDataDialogOpen] = useState(true)

    const [signerKey, setSignerKey] = useState(undefined)
    const [signerName, setSignerName] = useState(undefined)

    const [dialogValues, setDialogValues] = useState(undefined)
    const [dialogFields, setDialogFields] = useState(undefined)

    const [notificationSignerKey, setNotificationSignerKey] = useState(undefined)

    const [signerChangeDialogOpen, setSignerChangeDialogOpen] = useState('');

    const fetchEnvelopeData = useCallback(() => {
        const timer = setTimeout(() => {
            axios.get(`/dash/sign/envelope/${envelopeKey}`)
                .then((response) => {
                    setFetchedData(response.data)
                })
                .catch((error) => {
                    if ([404].includes((error.response || {}).status)) {
                        setError({
                            "has_error": true,
                            "status": error.response.status
                        })
                    } else {
                        setError({
                            "has_error": true,
                            "status": 500
                        })
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [envelopeKey, refresh])

    useEffect(() => {
        fetchEnvelopeData()
    }, [fetchEnvelopeData])

    const resendNotification = (signer_key) => {
        let data = {}
        setNotificationSignerKey(signer_key)
        setNotificationLoading(true)
        const timer = setTimeout(() => {
            axios.patch(`/dash/sign/signer/${signer_key}/notify_signer`, data)
                .then((response) => {
                    setNotificationError({
                        hasError: false,
                        statusCode: 200
                    })
                    setNotificationDialogOpen(true)
                })
                .catch((error) => {
                    setNotificationError({
                        hasError: true,
                        statusCode: error.response.status
                    })
                })
                .finally(() => {
                    setNotificationLoading(false)
                    setNotificationDialogOpen(true)
                    fetchEnvelopeData()
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }

    const setDocumentReportLoading = (index, value) => {
        const newLoading = [...reportLoading];
        newLoading[index] = value;
        setReportLoading(newLoading);
      };

    const downloadReport = (documentKey, index) => {
        let data = {}
        setDocumentReportLoading(index, true);
        const timer = setTimeout(() => {
            axios.get(`/dash/sign/envelope/${envelopeKey}/document/${documentKey}/report`, data)
                .then((response) => {
                    setDocumentReport(response.data)
                })
                .catch((error) => {
                    setReportError(true)
                })
                .finally(() => {
                    setDocumentReportLoading(index, false);
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }

    const [documentKey, setDocumentKey] = useState(undefined);
    const [documentFile, setDocumentFile] = useState(undefined);
    const [openFileDialog, setOpenFileDialog] = useState(false);
    const [documentFileLoading, setDocumentFileLoading] = useState(false);

    const handleCloseFileDialog = () => {
        setOpenFileDialog(false);
        setDocumentFile(null);
        setDocumentKey(null);
    };

    const downloadFile = useCallback(() => {

        setDocumentFileLoading(true)

        const timer = setTimeout(() => {
            axios.get(`/dash/sign/envelope/${envelopeKey}/document/${documentKey}/file`)
                .then((response) => {
                    setDocumentFile(response.data);
                    setOpenFileDialog(true)
                })
                .catch((error) => {
                })
                .finally(() => {
                    setDocumentFileLoading(false)
                });
        }, 500);
        return () => clearTimeout(timer);
    }, [envelopeKey, documentKey]);

    useEffect(() => {
        if (documentKey) {
            downloadFile();
        }
    }, [documentKey, downloadFile]);

    const handleDocumentKey = (documentKey) => {
        setDocumentKey(documentKey);
    };

    const cancelEnvelope = async (envelopeKey) => {
        const submissionData = {
            "status": "canceled"
        }
        cancelEnvelopeLoading(true)
        const timer = setTimeout(() => {
            axios.patch(`/dash/sign/envelope/${envelopeKey}`, submissionData)
                .then((response) => {
                    window.location.reload()
                })
                .catch((error) => {
                    setCancelEnvelopeError(true)
                })
                .finally(() => {
                    cancelEnvelopeLoading(false)
                    setLoading(false)
                })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }
    
    const copySignerSignatureUrl = (signer_key) => {
        const signer = fetchedData.signers.filter((signer) => (signer.signer_key === signer_key))[0]
        const url = signer.signature_url

        const input = document.createElement('input')
        input.setAttribute('value', url)
        document.body.appendChild(input)

        input.select()
        document.execCommand('copy')

        document.body.removeChild(input)
    }

    useEffect(() => {
        if (report) {
            const link = document.createElement('a')
            link.href = report.document_report_url
            link.click()
        }
    }, [report])

    const isNotUndefined = (value) => {
        if (value) return true
        else return false
    }

    const editSignerDataHandler = (signer_key) => {
        setSignerKey(signer_key)
        const signer = fetchedData.signers.filter((signer) => (signer.signer_key === signer_key))[0]
        setSignerName(signer.name)
        setDialogFields({
            phone: isNotUndefined(signer.phone),
            email: isNotUndefined(signer.email)
        })

        let values = {}
        if (isNotUndefined(signer.phone)) {
            const areaCode = signer.phone.area_code
            const number = `${signer.phone.number.slice(0, 5)}-${signer.phone.number.slice(5, 9)}`
            values["phone"] = `$(${areaCode})${number}`
        }
        if (isNotUndefined(signer.email)) {
            values["email"] = signer.email
        }
        setDialogValues(values)

        setEditSignerDataDialogOpen(true)
    }

    if (loading) {
        return (
            <LoadingPage />
        )
    } else if (error.hasError) {
        return (
            <ErrorPage />
        )
    } else if (fetchedData) {
        return (
            <div className="page-container">
                <ErrorBoundary>
                    <header className="gap--5">
                        <h1>
                            {`Envelope ${fetchedData.subject}`}
                        </h1>
                        <h2>
                            <span>ID do Envelope: </span>
                            <span id="not-bold">{fetchedData.id}</span>
                        </h2>
                    </header>
                </ErrorBoundary>
                <div style={{display: 'flex', justifyContent:'center'}}>
                    {userRoles.includes("cancel_envelope")
                        && (["on_signature", "submitted", "created"].includes(fetchedData.status)) && (
                        <div style={{marginRight:"12px"}}>
                            <BaseButton
                                label="CANCELAR ENVELOPE"
                                className="btn__container--center btn btn--red btn--animated"
                                onClick={() => cancelEnvelope(envelopeKey)}
                            >
                                {envelopeLoading ?
                                    <img className="btn__icon" src={LoadingIcon} alt="Loading" />
                                    :
                                <CancelRoundedIcon style={{ width: "1.8rem", height: "1.8rem" }}/>}
                            </BaseButton>
                        </div>
                        )}
                </div>
                <ul className="inbox">
                    <li className="inbox__header">
                        <div className="inbox__cell--left">Documentos</div>
                    </li>
                    <li className="inbox__body" style={{display: "flex", alignItems:  "center", justifyContent: "center", marginBottom: "24px"}}>
                        <div className="inbox__cell--column-container inbox__cell--gap-2">
                            {fetchedData.documents.map((document, index) => (
                                <div key={document.document_key} className="inbox__cell--row-container" style={{ gap:"16px"}}>
                                    <ErrorBoundary>
                                        <BaseAvatar value={index + 1} />
                                        <div className="inbox__cell--column-container" style={{flex: 1}}>
                                            <p key={document.document_key}>{document.name}</p>
                                            <DataField
                                                label="ID do Documento"
                                                field={document.id}
                                            />
                                        </div>
                                    </ErrorBoundary>
                                    <div>
                                        <BaseButton
                                            label="VISUALIZAR DOCUMENTO"
                                            onClick={() => handleDocumentKey(document.document_key)}
                                            className="btn__container--center btn btn--white btn--animated"
                                            isDisabled={documentFileLoading && document.document_key === documentKey}
                                                >
                                            {(documentFileLoading && document.document_key === documentKey) ?
                                                <img className="btn__icon" src={LoadingIcon} alt="Loading" />
                                                            :
                                            <FindInPageIcon 
                                                style={{ width: "16px", height: "16px", cursor: "pointer" }} 
                                            />}
                                        </BaseButton>
                                    </div>
                                    {userRoles.includes("load_envelope_report") && (
                                        <div>
                                            <BaseButton
                                                    label="COMPROVANTE DE ASSINATURA"
                                                    onClick={() => downloadReport(document.document_key, index)}
                                                    className="btn__container--center btn btn--white btn--animated"
                                                    isDisabled={reportLoading[index] || fetchedData.status !== "completed"}
                                                >
                                                    {reportLoading[index] ?
                                                        <img className="btn__icon" src={LoadingIcon} alt="Loading" />
                                                        :
                                                        <DownloadIcon style={{ width: "1.8rem", height: "1.8rem" }} />}
                                                </BaseButton>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </li>
                </ul>
                <ul className="inbox">
                    <li className="inbox__header">
                        <div className="inbox__cell--left">Assinantes:</div>
                        <div className="inbox__cell--center">Dados dos Assinantes:</div>
                        <div className="inbox__cell--center">Assinado em:</div>
                        <div className="inbox__cell--right">Status:</div>
                    </li>
                    {fetchedData.signers.map((signer) => (
                        <li key={signer.signer_key} className="inbox__body">
                            <ErrorBoundary>
                                <div className="inbox__cell--left">
                                    {['on_signature', 'submitted', 'created'].includes(signer.status) ?
                                        <>
                                            <div  style={{cursor: 'pointer', border: '1px solid #0b1f82', color: '#0b1f82', padding: '8px', borderRadius: '4px', marginLeft: '8px'}} onClick={() => setSignerChangeDialogOpen(signer.signer_key)}>Modificar assinante</div>
                                            <Dialog open={signerChangeDialogOpen === signer.signer_key}>
                                                <LegacySignerChangeDialog signer={signer} onClose={() => {
                                                    setSignerChangeDialogOpen('')
                                                    setRefresh(!refresh)
                                                }} />
                                            </Dialog>
                                        </> : <></>
                                    }
                                </div>
                                <div className="inbox__cell--left inbox__cell--row-container">
                                    {userRoles.includes("update_signer_data")
                                        && (["on_signature", "submitted", "created"].includes(signer.status)) ?
                                        <button
                                            className="btn btn--white btn--animated"
                                            onClick={() => editSignerDataHandler(signer.signer_key)}
                                        >
                                            <EditNoteIcon id={signer.signer_key} style={{ width: "1.8rem" }} />
                                        </button>
                                        : <div className="envelope__signer-cell-btn--width"></div>}
                                    <div className="envelope__signer-cell--width inbox__cell--column-container">
                                        {signer.document_number &&
                                            <DataField
                                                label="Documento"
                                                field={signer.document_number}
                                            />}
                                        {signer.birthdate &&
                                            <DataField
                                                label="Data de Nascimento"
                                                field={formatBirthdateToFront(signer.birthdate)}
                                            />}
                                        {signer.name &&
                                            <DataField
                                                label="Nome do assinante"
                                                field={signer.name}
                                            />}
                                        {signer.email &&
                                            <DataField
                                                label="Email"
                                                field={signer.email}
                                            />}
                                        {signer.phone &&
                                            <DataField
                                                label="Telefone"
                                                field={`${signer.phone.international_dial_code} (${signer.phone.area_code}) ${signer.phone.number.slice(0, 5)}-${signer.phone.number.slice(5, 9)}`}
                                            />} 
                                        {signer.signature_role ?
                                            <DataField
                                                label="Papel de assinatura"
                                                field={translateSignatureRole(signer.signature_role)}
                                            /> : <></>
                                        }
                                    </div> 
                                </div> 
                                {signer.signed_at ?
                                    <div className="inbox__cell--center">
                                        <DatetimeBlock dateString={signer.signed_at} />
                                    </div>
                                    : <div className="envelope__date-time--height inbox__cell--center"></div>}
                                <div className="inbox__cell--right">
                                    <div className="inbox__cell--row-container">
                                        {userRoles.includes("renotify_signer") && (signer.status === "on_signature") &&
                                            <ThemeProvider theme={theme}>
                                                <Tooltip title="Reenviar notificação de assinatura" placement="top">
                                                    <button className="btn btn--animated btn--white" onClick={() => !notificationLoading ? resendNotification(signer.signer_key) : null}>
                                                        {(notificationLoading&&notificationSignerKey===signer.signer_key)?
                                                        <CircularProgress style={{ color: "#2d2d2d" }} size={"1.6rem"} />
                                                        :
                                                            (signer.document_submission_method==="sms")?
                                                                <SendToMobileOutlinedIcon style={{ width: "1.8rem", color: "#525252" }} />
                                                                :<SendEmailIcon style={{ width: "1.8rem", color: "#525252" }} />
                                                        }
                                                    </button>
                                                </Tooltip>
                                            </ThemeProvider>
                                            }
                                            <ThemeProvider theme={theme}>
                                                <Tooltip title="Copiar link de assinatura" placement="top">
                                                    <button className="btn btn--animated btn--white" onClick={() => copySignerSignatureUrl(signer.signer_key)}>
                                                        <ContentCopyIcon style={{ width: "1.8rem", color: "#525252" }} />
                                                    </button>
                                                </Tooltip>
                                            </ThemeProvider>
                                        <StatusIconText
                                            status={signer.status}
                                            type="signer_status"
                                        />
                                    </div>
                                </div>
                            </ErrorBoundary>
                        </li>
                    ))}
                </ul>
                {documentFile && (
                    <>
                        <MuiDialog 
                        open={openFileDialog}
                        onClose={() => handleCloseFileDialog()}
                        >
                            <PDFContent 
                                pdfFile={documentFile.document_file} 
                            />
                        </MuiDialog>
                    </>
                )}
                {signerKey && signerName && dialogFields &&
                <SignerDataDialog
                    open={editSignerDataDialogOpen}
                    setOpen={setEditSignerDataDialogOpen}
                    signerKey={signerKey}
                    setSignerKey={setSignerKey}
                    signerName={signerName}
                    setSignerName={setSignerName}
                    fetchEnvelopeData={fetchEnvelopeData}
                    values={dialogValues}
                    setValues={setDialogValues}
                    fields={dialogFields}
                />}
                <NotificationDialog
                    open={notificationDialogOpen}
                    setOpen={setNotificationDialogOpen}
                    notificationError={notificationError}
                    setNotificationError={setNotificationError}
                />
                <ReportErrorDialog
                    reportError={reportError}
                    setReportError={setReportError}
                />
                <CancelEnvelopeErrorDialog
                    cancelEnvelopeError={cancelEnvelopeError}
                    setCancelEnvelopeError={setCancelEnvelopeError}
                />
            </div>
        )
    }
}